import React from 'react';
import { useWindowDimensions } from 'react-native';
import { View, Text } from '../../components/new';
import AutoBottomSheet from '../../components/new/custom/AutoBottomSheet';
import CachedImage from '../../components/new/custom/CachedImage';
import { imageTransformation } from '../../utilities/helper';
import photoMoments from '../Strings';

const PreviewImageBottomSheet = ({ bottomSheetModalRef, assetId }) => {
  const { width, height } = useWindowDimensions();
  return (
    <AutoBottomSheet bottomSheetRef={bottomSheetModalRef}>
      <View>
        <View px="2xl" mt="4xl">
          <Text
            size="3xl"
            color="primary.500"
            textAlign="center"
            weight="medium"
          >
            {photoMoments.preview}
          </Text>
          <Text size="md" color="primary.200" textAlign="center" pt="md">
            {photoMoments.confirmPreview}
          </Text>
          <CachedImage
            style={[
              {
                width: width - 32,
                height: height * 0.6,
                justifyContent: 'center',
                alignItems: 'center',
                alignSelf: 'center',
                marginTop: 24,
                resizeMode: 'contain',
              },
            ]}
            source={{
              uri: imageTransformation(
                `https://ik.imagekit.io/32ndhub/photoMoment/${assetId}`,
                (width - 32) * 0.8,
                `${(width - 32) * 0.8}-${height * 0.6}`,
              ),
            }}
          />
        </View>
      </View>
    </AutoBottomSheet>
  );
};

export default PreviewImageBottomSheet;
