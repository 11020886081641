/* eslint-disable no-unsafe-optional-chaining */
import React, { Suspense, useEffect, useState } from 'react';
import { SafeAreaView, useWindowDimensions } from 'react-native';
import { useLazyLoadQuery } from 'react-relay';
import * as FileSystem from 'expo-file-system';
import * as MediaLibrary from 'expo-media-library';
import * as Sharing from 'expo-sharing';
import {
  Button,
  IconButton,
  Layout,
  Text,
  TopNavigation,
  View,
} from '../../../components/new';
import { Loading } from '../../../components';
import NewErrorBoundary, {
  NewErrorBoundaryParentState,
} from '../../../utilities/NewErrorBoundary';
import NewErrorView from '../../../utilities/NewErrorView';
import DisconnectedDropover from '../../../components/disconnectedpopover.component';
import {
  imageTransformation,
  navigateBack,
  PaymentGatewayType,
  paymentOrderTypeEnum,
} from '../../../utilities/helper';
import useUserStore, { UserRole } from '../../../stores/userStore';
import { firebaseEventLogger } from '../../../utilities/firbaseAnalytics';
import photoMoments from '../../Strings';
import { useSnackbarStore } from '../../../stores/snackbar/snackbarStore';
import {
  SnackbarStatus,
  SnackbarVersion,
} from '../../../components/new/primitive/snackbar/helpers/helpers';
import { isValueNullOrEmpty } from '../../../utilities/Utility';
import ScanQrCodeScreen from '../../valetSupervisor/new/recall/ScanQrCodeScreen';
import CachedImage from '../../../components/new/custom/CachedImage';
import { Appearance, Directions, State } from '../../../themes/new/helper';
import { initiatePaymentAPI } from '../payment/api/paymentAmountScreenQuery';
import { printMomentsQuery } from './printMomentsQuery';
import useCommonStore from '../../../stores/commonStore';
import { createPopupOrderAPI } from '../../API/createPopupOrder';

let imageCache = {};
const PRINT_AMOUNT = 200;

const PrintMoments = ({ navigation }) => {
  const [refreshedQueryOptions, setRefreshedQueryOptions] =
    useState<NewErrorBoundaryParentState>({
      fetchKey: 0,
      fetchPolicy: 'network-only',
    });
  const [netStatus, setNetStatus] = useState(true);
  const [assetId, setAssetId] = useState('');
  const [userId, setUserId] = useState('');
  const { role: userRole, id } = useUserStore((state) => state);
  const { campusId } = useCommonStore((state) => state);
  const [permissionResponse, requestPermission] = MediaLibrary.usePermissions();
  const { dispatchSnackbar } = useSnackbarStore((state) => state);
  const [loading, setLoading] = useState(false);

  const onUnmount = () => {
    Object.keys(imageCache).forEach(async (fileUri) => {
      await FileSystem.deleteAsync(fileUri);
    });
    imageCache = {};
  };

  useEffect(() => {
    return () => {
      onUnmount();
    };
  }, []);

  const onPressLeftIcon = () => {
    navigateBack(navigation, userRole);
  };

  const refresh = () => {
    setRefreshedQueryOptions((prev) => ({
      ...prev,
      fetchKey: (prev?.fetchKey || 0) + 1,
    }));
  };
  const variables = {
    filter: { ownerId: id, campus: { _id: campusId } },
  };
  const { height, width } = useWindowDimensions();
  const { findPopUpStores } = useLazyLoadQuery(printMomentsQuery, variables, {
    ...refreshedQueryOptions,
    networkCacheConfig: { force: true },
  });
  const orderId = findPopUpStores?.edges[0]?.node?._id;

  const saveFile = async ({ fileUri }) => {
    const { status } = await requestPermission();
    if (status === 'granted') {
      try {
        const asset = await MediaLibrary.createAssetAsync(fileUri);
        const album = await MediaLibrary.getAlbumAsync('32nd');
        if (album == null) {
          await MediaLibrary.createAlbumAsync('32nd', asset, true);
        } else {
          MediaLibrary.addAssetsToAlbumAsync([asset], album.id, true);
        }
        setLoading(false);
        await FileSystem.deleteAsync(fileUri);
        dispatchSnackbar({
          msg: photoMoments.imageDownloaded,
          status: SnackbarStatus.success,
          version: SnackbarVersion.v1,
        });
        firebaseEventLogger('printMoment__downloadSuccess_data', {
          buttonName: 'downloadSuccess',
          screenName: 'printMoment',
          userType: 'VideoSupervisor',
          interactionType: 'data',
          imageId: assetId,
          permission: status,
        });
      } catch (error) {
        console.log('eeeee-', error);
        setLoading(false);
        dispatchSnackbar({
          msg: `Oops, Something went wrong!`,
          status: SnackbarStatus.error,
          version: SnackbarVersion.v2,
        });
        firebaseEventLogger('printMoment__downloadFailed_data', {
          buttonName: 'downloadFailed',
          screenName: 'printMoment',
          userType: 'VideoSupervisor',
          interactionType: 'data',
          imageId: assetId,
          permission: status,
        });
      }
    }
  };

  const handleDownload = async () => {
    firebaseEventLogger('printMoment__AddImages_Tap', {
      buttonName: 'Add Images',
      screenName: 'printMoment',
      userType: 'VideoSupervisor',
      interactionType: 'tap',
      imageId: assetId,
    });

    try {
      setLoading(true);
      const assetLink = `https://ik.imagekit.io/32ndhub/photoMoment/${assetId}`;
      let fileUri = `${FileSystem.documentDirectory}/${assetId}`;
      fileUri = fileUri.replace(' ', '');
      const res = await FileSystem.downloadAsync(assetLink, fileUri);
      await saveFile({ fileUri: res.uri });
    } catch (err) {
      setLoading(false);
      firebaseEventLogger('printMoment__AddImages_Failed_Tap', {
        buttonName: 'downloadFailed',
        screenName: 'printMoment',
        userType: 'VideoSupervisor',
        interactionType: 'data',
        imageId: assetId,
      });
    }
  };

  const handleCreateOrder = () => {
    const data = {
      record: {
        amount: PRINT_AMOUNT,
        userId,
        referenceModelId: orderId,
        itemDetails: assetId,
        referenceModelName: paymentOrderTypeEnum.POPUP_ORDER,
        campus: {
          _id: campusId,
        },
      },
    };
    createPopupOrderAPI(
      data,
      (res) => {
        if (res && res?.createPopupOrder && res?.createPopupOrder?.record) {
          navigation.navigate('home');
        }
      },
      (err) => {
        dispatchSnackbar({
          msg: err?.error?.message || 'Something Went Wrong!',
          status: SnackbarStatus.error,
          version: SnackbarVersion.v2,
          position: Directions.TOP,
          removeAfter: 4000,
        });
      },
    );
  };

  const handlePrint = async () => {
    firebaseEventLogger('printMoment__PrintNow_Tap', {
      buttonName: 'Print Now',
      screenName: 'printMoment',
      userType: 'VideoSupervisor',
      interactionType: 'tap',
      imageId: assetId,
    });
    setLoading(true);
    const assetLink = `https://ik.imagekit.io/32ndhub/photoMoment/${assetId}`;
    let fileUri = `${FileSystem.documentDirectory}/${assetId}`;
    fileUri = fileUri.replace(' ', '');
    try {
      handleCreateOrder();
      const fileInfo = await FileSystem.getInfoAsync(fileUri);
      if (fileInfo?.exists) {
        Sharing.shareAsync(fileUri);
      } else {
        const res = await FileSystem.downloadAsync(assetLink, fileUri);
        imageCache[fileUri] = res?.uri;
        Sharing.shareAsync(fileUri);
      }
      setLoading(false);
    } catch {
      setLoading(false);
      firebaseEventLogger('printMoment__downloadFailed_data', {
        buttonName: 'downloadFailed',
        screenName: 'printMoment',
        userType: 'VideoSupervisor',
        interactionType: 'data',
        imageId: assetId,
      });
    }
  };

  const checkAssetId = (data: string): boolean => {
    return (
      data.includes('.gif') ||
      data.includes('.jpeg') ||
      data.includes('.png') ||
      data.includes('.jpg')
    );
  };

  if (isValueNullOrEmpty(assetId)) {
    return (
      <ScanQrCodeScreen
        navigation={navigation}
        route={undefined}
        printPhoto
        backtoStaffMoments={onPressLeftIcon}
        scannedAssetId={(data) => {
          const newData = JSON.parse(data);
          if (checkAssetId(newData?.assetId)) {
            setAssetId(newData?.assetId);
            setUserId(newData?.userId);
            firebaseEventLogger('printMoment__successfullyScan_data', {
              buttonName: 'successfullyScan',
              screenName: 'printMoment',
              userType: 'VideoSupervisor',
              interactionType: 'data',
              imageId: data,
            });
          } else {
            dispatchSnackbar({
              msg: `Please scan a valid QR Code!`,
              status: SnackbarStatus.error,
              version: SnackbarVersion.v2,
            });
            firebaseEventLogger('printMoment__unSuccessfullScan_data', {
              buttonName: 'unSuccessfullScan',
              screenName: 'printMoment',
              userType: 'VideoSupervisor',
              interactionType: 'data',
              imageId: data,
            });
          }
        }}
      />
    );
  }

  return (
    <Layout level={2}>
      <TopNavigation
        IconLeft={
          <IconButton
            name="back-outline-300"
            size="md"
            appearance="ghost"
            iconSize="2xl"
            iconColor="primary.500"
            shape="square"
            onPress={onPressLeftIcon}
          />
        }
        appearance="ghost"
        level="1"
        title={
          userRole !== UserRole.MOMENT_STAFF_PARTNER ? 'Print Moments' : ''
        }
      />
      <SafeAreaView style={{ flex: 1 }}>
        <NewErrorBoundary
          fetchKey={refreshedQueryOptions.fetchKey}
          fallback={
            <NewErrorView
              errorMsg="Sorry something went wrong"
              reload={refresh}
            />
          }
        >
          <Suspense fallback={<Loading />}>
            <View px="2xl" mt="4xl" flex={1}>
              <Text
                size="3xl"
                color="primary.500"
                textAlign="center"
                weight="medium"
              >
                {photoMoments.preview}
              </Text>
              <Text size="md" color="primary.200" textAlign="center" pt="md">
                {photoMoments.confirmPreview}
              </Text>
              <CachedImage
                style={[
                  {
                    width: width - 32,
                    height: height * 0.6,
                    justifyContent: 'center',
                    alignItems: 'center',
                    alignSelf: 'center',
                    marginTop: 24,
                    resizeMode: 'contain',
                  },
                ]}
                source={{
                  uri: imageTransformation(
                    `https://ik.imagekit.io/32ndhub/photoMoment/${assetId}`,
                    (width - 32) * 0.8,
                    `${(width - 32) * 0.8}-${height * 0.6}`,
                  ),
                }}
              />
              <View flex={1} mb="2xl" flexDirection="row" alignItems="flex-end">
                <View flex={1}>
                  <Button
                    appearance={
                      userRole !== UserRole.MOMENT_STAFF_PARTNER
                        ? Appearance.OUTLINE
                        : Appearance.FILLED
                    }
                    size="md"
                    onPress={() => {
                      handlePrint();
                    }}
                  >
                    {userRole !== UserRole.MOMENT_STAFF_PARTNER
                      ? 'Print Now'
                      : 'Initiate Print'}
                  </Button>
                </View>

                {userRole !== UserRole.MOMENT_STAFF_PARTNER ? (
                  <View ml="2xl" flex={1}>
                    <Button
                      appearance={Appearance.FILLED}
                      state={State.ACTIVE}
                      size="md"
                      onPress={() => {
                        handleDownload();
                      }}
                      // ml={"2xl"}
                      // px="9xl"
                      // style={{paddingLeft: space['9xl'], paddingRight: space['9xl']}}
                    >
                      Add Images
                    </Button>
                  </View>
                ) : (
                  <></>
                )}
              </View>
              {loading && (
                <View
                  justifyContent="center"
                  alignItems="center"
                  height={height}
                  width={width}
                  position="absolute"
                  bottom={0}
                >
                  <Loading />
                </View>
              )}
            </View>
          </Suspense>
        </NewErrorBoundary>
        <DisconnectedDropover
          setNetStatus={setNetStatus}
          text="No Internet Connection"
          icon="wifi-off-outline"
        />
      </SafeAreaView>
    </Layout>
  );
};
export default PrintMoments;
